import { AccentBtn } from '@playbooks/interface/buttons';
import { Container } from '@playbooks/interface/grid';
import { Link } from '@playbooks/interface/links';
import { AppWrapper } from 'components/app/app-wrapper';
import { useIntercom } from 'contexts';
import { Feedback } from 'molecules/feedbacks';

const NotFoundRoute = ({ ssr, account, router, session, store, toast }) => {
	const intercom = useIntercom();
	// Render
	return (
		<AppWrapper breadcrumbs seo={{ title: 'Playbooks | Not Found' }}>
			<Container size='xl' className='py-4'>
				<Feedback type='page' icon='map' title='404' text='Not Found'>
					<Link href='/'>Visit Home</Link>
					<AccentBtn onClick={intercom.show}>Contact Support</AccentBtn>
				</Feedback>
			</Container>
		</AppWrapper>
	);
};

export default NotFoundRoute;
