import { Div, Main } from '@playbooks/interface/html';
import { Route } from '@playbooks/interface/routes';
import { AdminBanner } from 'components/admin/admin-banner';
import { AppBreadcrumbs } from 'components/app/app-breadcrumbs';
import { AppFooter } from 'components/app/app-footer';
import { AppNav } from 'components/app/app-nav';
import { useStorage } from 'contexts';

type iWrapper = {
	seo?: any;
	breadcrumbs?: boolean;
	children: any;
	tailwind?: any;
};

const AppWrapper = ({ seo, breadcrumbs, children, tailwind }: iWrapper) => {
	const storage = useStorage();
	const tempToken = storage.loaded && storage.storage.tempToken?.id;

	// Render
	return (
		<Route seo={seo} display='flex-column' height='min-h-[100vh]'>
			<Div position='fixed' inset='left-0 right-0 top-0' zIndex='z-20'>
				<AdminBanner />
				<AppNav {...tailwind?.nav} />
			</Div>
			<Main flex='grow' height='h-full' spacing={tempToken ? 'pt-[130px] mb-8' : 'pt-[75px]'} {...tailwind?.main}>
				{breadcrumbs && <AppBreadcrumbs tailwind={tailwind} />}
				{children}
			</Main>
			<AppFooter />
		</Route>
	);
};

export { AppWrapper };
